/* eslint-disable react/jsx-indent */
import React from 'react';
import Layout from '../templates/Page';
import SEO from '../templates/components/SEO';

const CancelPage = () => {
    return (
		<Layout>
		  <SEO title="Thank you for your order!" />
		  <header className="page-header">
		    <div className="page-header__wrapper">
		      <h1 className="page-header__title">Whoops</h1>
		    </div>
		  </header>
		  <div className="block block--xl-on-md">
		    <div className="wrapper wrapper--xs">
		      
		      <div className="template-content">
                  <p>Your order has been cancelled.</p>
              </div>
            
		    </div>
		  </div>
          <style jsx>{`
            p {
                text-align: center;
            }
          `}</style>

		</Layout>
		
    );
};

export default CancelPage;